const es = {
  LEGEND_TITLE: "Agregue capas al mapa interactivo",
  LEGEND_TITLE_MOBILE: "Capas y Simbología del Mapa Interactivo",
  LEGEND_VIEW_ALL: "Ver todo",
  LEGEND_VIEW_NONE: "Limpiar",
  LEGEND_ITEM_TOOLTIP_PREFIX: "Ver u ocultar", // prefix the legend item
  POPUP_IMAGE_ALT_PREFIX: "Imagen de la Estación",
  POPUP_MORE_INFO: "Más información"
};

const en = {
  LEGEND_TITLE: "English Legend Title",
  LEGEND_VIEW_ALL: "View All",
  LEGEND_VIEW_NONE: "View None",
  POPUP_IMAGE_ALT_PREFIX: "Image of station",
  POPUP_MORE_INFO: "More information"
};

export { es, es as default, en };
