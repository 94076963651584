export default {
  "toggle-turismo": {
    label: "Estaciones y puestos",
    color: "#CCCC00",
    filterOn: "symbol",
    hasPopups: true,
    filters: [
        {
            value: "tourist",
            label: "Atención a Turistas"
        },
      {
        value: "biological",
        label: "Estaciones Biológicas"
      }

    ]
  },
  "toggle-unesco": { label: "Sitio Patrimonio de la Humanidad", color: "#fddc08" },
  "toggle-sectores": {
    label: "Sectores",
    color: "#81b06d",
    combineWithLayers: ["sectores-nombres", "sectores-nombres-6mdotv"]
  },
  "toggle-ecosistemas": {
    label: "Ecosistemas",
    color: "#444444",
    filterOn: "Name",
    filters: [
      {
        value: "Marino",
        label: "Marino",
        color: "hsl(221, 100%, 72%)"
      },
      {
        value: "Bosque Seco",
        label: "Bosque Seco",
        color: "hsl(64, 100%, 66%)"
      },
      {
        value: "Bosque Lluvioso",
        label: "Bosque Lluvioso",
        color: "hsl(127, 96%, 64%)"
      },
      {
        value: "Bosque Nuboso",
        label: "Bosque Nuboso",
        color: "hsl(305, 100%, 66%)"
      }
    ]
  },
    "asp":{
      name: "asp",
    label:"Área Silvestre Protegida",
        color: "transparent",
        class: "legend-asp"
    },
    "amp":{
      name: "amp",
    label: "Área Silvestre Marina Protegida",
        color:"transparent",
        class:"lengend-marino"
    },
    "ap":{
      name: "ap",
        label:"Agropaisaje",
        color: "transparent",
        class:"legend-agro"
    },


};
